import { $, $$ } from '../helpers/query-selector';

const navDesktop = $('.js-nav-desktop');
const navRubrics = $$('.js-nav-rubric', navDesktop);
const anchorNav = $('.js-anchor-nav');

function alignSublist(subListToggle, subList) {
  // Sublist position
  const toggleRect = subListToggle.getBoundingClientRect();

  // Regular position left minus container padding
  let left = -36;

  // Detect overflow
  const toggleCenter = toggleRect.left + toggleRect.width / 2;
  const subListWidth = subList.offsetWidth;
  const bodyWidth = document.body.offsetWidth; // window.innerHeight does not work b/c overflow

  const overflowRight = toggleCenter + subListWidth / 2;
  const overflowLeft = toggleCenter - subListWidth / 2;

  if (overflowRight >= bodyWidth) {
    const offset = overflowRight - bodyWidth + 5;
    subList.style.setProperty('--offset', offset + 'px');

    left = left - offset;
  } else if (overflowLeft < 0) {
    const offset = overflowLeft - 5;
    subList.style.setProperty('--offset', offset + 'px');

    left = left - offset;
  }

  subList.style.setProperty('left', left + 'px');
}

function showSubList(subListToggle, subList) {
  // Align sublist
  alignSublist(subListToggle, subList);

  const subLists = $$('.js-nav-sublist', navDesktop);

  for (const list of subLists) {
    if (list.classList.contains('visible')) list.classList.remove('visible');
  }

  subListToggle.setAttribute('aria-expanded', 'true');
  subList.classList.add('visible');

  // Fix sublist offset for top line
  if (anchorNav || window.scrollY == 0) {
    subList.style.setProperty('--offset', '0');
  } else {
    subList.style.setProperty('--offset', '-1.5px');
  }
}

function hideSubList(subListToggle, subList) {
  subListToggle.setAttribute('aria-expanded', 'false');
  subList.classList.remove('visible');
}

function init() {
  if (!navDesktop) return;

  for (const rubric of navRubrics) {
    const subListToggle = $('.js-nav-toggle-sublist', rubric);
    const subList = $('.js-nav-sublist', rubric);

    // Hover behaviour
    subListToggle.addEventListener('mouseover', () => {
      showSubList(subListToggle, subList);
    });

    rubric.addEventListener('mouseleave', () => {
      if (subList.classList.contains('visible'))
        hideSubList(subListToggle, subList);
    });

    // Keydown behaviour
    subListToggle.addEventListener('keydown', e => {
      if (e.key === 'Enter' || e.key === ' ') {
        subList.classList.contains('visible')
          ? hideSubList(subListToggle, subList)
          : showSubList(subListToggle, subList);
      }
    });

    // Disable opening the sublist on mouse click to prevent closing it after hover on click
    subListToggle.addEventListener('mousedown', e => {
      if (e.subListToggle === 0) {
        e.preventDefault();
      }
    });

    // Hide sublist on anchor clicks
    const anchors = $$('.js-anchor-scroll', navDesktop);

    for (const anchor of anchors) {
      anchor.addEventListener('click', () => {
        hideSubList(subListToggle, subList);
      });
    }
  }
}

export { init as default, hideSubList };
