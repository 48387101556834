import lazySizes from 'lazysizes';
import 'lazysizes/plugins/optimumx/ls.optimumx';
import { $$ } from '../helpers/query-selector';
import { saveData, viewport } from '../helpers/variables';

// Image lazyload
lazySizes.cfg.init = false;

function initImageLazyLoad() {
  // Load lower resolution images if saveData
  if (saveData) {
    const images = $$('.lazyload');
    for (const image of images) image.dataset.optimumx = 1;
  }

  // Remove transparent bg on lazyload
  document.addEventListener('lazyloaded', e => {
    const elementClasses = ['c-image__wrapper', 'c-iframe__wrapper'];

    for (const elementClass of elementClasses) {
      const wrapper = e.target.closest(`.${elementClass}`);
      if (wrapper) wrapper.classList.add(`${elementClass}--transparent`);
    }
  });

  lazySizes.init();
}

function init() {
  initImageLazyLoad();
}

export default init;
