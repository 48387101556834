import { $, $$ } from '../helpers/query-selector';
import { viewport } from '../helpers/variables';

const navToggle = $('.js-nav-toggle');
const navIcon = $('.js-nav-icon');
const navMobile = $('.js-nav-mobile');

const navIconActiveClass = 'c-nav-icon--active';

let navVisible = false;

function showNav() {
  navVisible = true;

  navToggle.setAttribute('aria-expanded', 'true');
  navIcon.classList.add(navIconActiveClass);
  navMobile.classList.add('active');

  const anchors = $$('.js-anchor-scroll', navMobile);

  for (const anchor of anchors) {
    anchor.addEventListener('click', () => {
      hideNav();
    });
  }
}

function hideNav() {
  navVisible = false;

  navToggle.setAttribute('aria-expanded', 'false');
  navIcon.classList.remove(navIconActiveClass);
  navMobile.classList.remove('active');
}

function initSublistToggle() {
  if (!navMobile) return;

  const navRubrics = $$('.js-nav-rubric', navMobile);

  for (const rubric of navRubrics) {
    const subListToggle = $('.js-nav-toggle-sublist', rubric);
    const subList = $('.js-nav-sublist', rubric);

    subListToggle.addEventListener('click', () => {
      subListToggle.classList.toggle('active');
      subList.classList.toggle('visible');
      subList.classList.contains('visible')
        ? subListToggle.setAttribute('aria-expanded', 'true')
        : subListToggle.setAttribute('aria-expanded', 'false');
    });
  }
}

function init() {
  if (!navToggle) return;

  initSublistToggle();

  navToggle.addEventListener('click', () => {
    navVisible ? hideNav() : showNav();
  });

  // Close nav conditions
  viewport.m.addEventListener('change', e => {
    if (e.matches) hideNav();
  });

  document.addEventListener('keydown', e => {
    if (e.key === 'Esc' || e.key === 'Escape') hideNav();
  });
}

export default init;
