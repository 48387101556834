import { $$ } from '../helpers/query-selector.js';
import * as basicScroll from 'basicscroll';

function init() {
  const elements = $$('.js-animate');

  for (const el of elements) {
    let animationTriggerStart = 0;
    let animationTriggerEnd = 'bottom-top';
    let animationPositionStart = '20%';
    let animationPositionEnd = '-10%';

    if (el.dataset.animationTriggerStart) {
      animationTriggerStart = el.dataset.animationTriggerStart;
    }

    if (el.dataset.animationTriggerEnd) {
      animationTriggerEnd = el.dataset.animationTriggerEnd;
    }

    if (el.dataset.animationPositionStart) {
      animationPositionStart = el.dataset.animationPositionStart;
    }

    if (el.dataset.animationPositionEnd) {
      animationPositionEnd = el.dataset.animationPositionEnd;
    }

    const instance = basicScroll.create({
      elem: el,
      from: animationTriggerStart,
      to: animationTriggerEnd,
      direct: true,
      props: {
        '--translateY': {
          from: animationPositionStart,
          to: animationPositionEnd
        }
      }
    });

    instance.start();
  }
}

export default init;
